import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div style={{paddingLeft: "2rem", paddingRight:"2rem", paddingBottom: "20rem"}}>

    
    <h1>Privacy Policy</h1>
    <p>Effective Date: October 19, 2023</p>
    <h3>1. Introduction</h3>
    <p>
      Welcome to Triangula Scout ("we," "our," or "us"). We are committed to
      protecting your privacy and maintaining the confidentiality of your
      personal information. This Privacy Policy outlines how we collect, use,
      disclose, and safeguard your personal information when you use our mobile
      application Triangula Scout (the "App").
    </p>
    <h3>2. Information We Collect</h3>
    <h4>2.1 Information You Provide</h4>
    <p>
      • When you register the App, you have the option to provide a display name of your choice.
    </p>
    <p>
      • When you contact us for support or feedback, we may collect information related to your inquiries.
    </p>
    <h4>2.2 Automatically Collected Information</h4>
    <p>
      • We may collect device information, such as your device type, operating system.
    </p>
    <p>
      • We may collect usage information, such as the features you use and the actions you take within the App.
    </p>
    <p>
      • We may also collect location data and metadata needed for gunshot detection when you use specific features or services within the App that require this functionality. This data may include but is not limited to your device's GPS coordinates and audio metadata related to gunshot events.
    </p>
    <p>• We may collect log data.</p>
    <h3>3. How We Use Your Information</h3>
    <p>We may use your information for the following purposes:</p>
    <p>• To provide and maintain the App's functionality.</p>
    <p>• To improve our App and services.</p>
    <p>
      • To communicate with you, including responding to your inquiries and
      providing customer support.
    </p>
    <p>• To comply with legal obligations.</p>
    <p>
      • To collect and analyze information for the purpose of detecting and responding to gunshot events in order to enhance public safety and security.
    </p>
    <h3>4. Disclosure of Your Information</h3>
    We may only share your information with third parties, including:
    <p>
      • Service providers who assist with App functionality and maintenance.
    </p>
    <p>
      • Law enforcement or government authorities in response to legal requests.
    </p>
    <h3>5. Security</h3>
    <p>
      We take reasonable measures to protect your information from unauthorized access or disclosure.
    </p>
    <h3>6. Changes to This Privacy Policy</h3>
    <p>
      We may update this Privacy Policy to reflect changes in our practices. Please review this Privacy Policy periodically.
    </p>
    <h3>7. Contact Us</h3>
    <p>
      If you have any questions or concerns about this Privacy Policy, please contact us at contact@triangula.com.
    </p>
    </div>
  </Layout>
)

export default PrivacyPolicyPage
